.wrapper {
  max-width:100%;
}
.base_padding {
  padding: rem(25px) rem(235px);
  @include media-max($lg) {
    padding: rem(25px) rem(50px);
  }
  @include media-max($md) {
    padding: rem(25px) rem(15px);
  }
}
.small_padding {
  padding: rem(25px) rem(50px);
  @include media-max($lg) {
    padding: rem(25px) rem(25px);
  }
  // @include media-max($md) {
  //   padding: rem(25px) rem(10px);
  // }
}
.glass_box {
  width: 100%;
  background: #ececec;
  -webkit-box-shadow: 0px 0px 20px 5px #ffffff3b; 
  box-shadow: 0px 0px 20px 5px #ffffff3b;
  border-radius: 15px;
 border: 1px solid rgba(255, 255, 255, 0.514);
}
.dark_bg {
      background: rgba(7, 7, 7, 0.336) ;
      backdrop-filter: blur(21px);
      box-shadow: 0px 0px 20px 5px #ffffff3b;
     
}
ul.dark_bg {
  @include media-max($md) {
    background: rgba(0, 0, 0, 0.959);
  }
}


.scroll-down,
.scroll-up {
	position: absolute;
	bottom: 30px;
	display: block;
	text-align: center;
	font-size: 20px;
	z-index: 100;
	text-decoration: none;
	text-shadow: 0;
  width: 30px;
  height: 30px;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  z-index: 1;
  left: 50%;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
	-webkit-animation: fade_move_down 2s ease-in-out infinite;
	-moz-animation:    fade_move_down 2s ease-in-out infinite;
	animation:         fade_move_down 2s ease-in-out infinite;
  @include media-max ($md) {
    width: 20px;
    height: 20px;
    bottom: 15px;
  }
}
.scroll-up {

	bottom: 100%;


  border-bottom: 4px solid rgba(255, 255, 255, 0);
  border-right: 4px solid rgba(255, 255, 255, 0);

  border-top: 4px solid #fff;
  border-left: 4px solid #fff;

  -webkit-animation: fade_move_up 2s ease-in-out infinite;
	-moz-animation:    fade_move_up 2s ease-in-out infinite;
	animation:         fade_move_up 2s ease-in-out infinite;
}


/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0%   { -webkit-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
@-moz-keyframes fade_move_down {
  0%   { -moz-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
@keyframes fade_move_down {
  0%   { transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,10px) rotate(45deg); opacity: 0; }
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_up {
  0%   { -webkit-transform:translate(0,10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,-10px) rotate(45deg); opacity: 0; }
}
@-moz-keyframes fade_move_up {
  0%   { -moz-transform:translate(0,10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,-10px) rotate(45deg); opacity: 0; }
}
@keyframes fade_move_up {
  0%   { transform:translate(0,10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,-10px) rotate(45deg); opacity: 0; }
}


div.clicked_mobile {
  background: transparent;
  box-shadow: none;
}
.history {
  color: $orange;
}
.facebook > span {
  color: #3b5998;
}
.linkedin > span{
  color:#0072b1;
}
.twitter > span {
  color:#00acee;
}
.whatsapp > span {
  color:#25D366;
}
.email {}
.phone{}