header {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  justify-content: flex-start;
  z-index: 3;
  ul {
    list-style: none;
    padding: rem(20px 25px); 
    width: max-content;
    margin-right: rem(60px);
    border-radius: 15px;
    color: $white;
    border: 1px solid rgba(255, 255, 255, 0.514);

    li {
      margin: rem(0 25px);
      a {
        font-size: rem(22px);
      }
    }
  }
}
.sticky {
  padding: rem(60px 0);
  @include media-max($md) {
    padding: rem(20px 0);
  }

}
.nav_wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: rem(50px);
}
.nav {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 71px;
  }
}
//

.icon-1, .icon-2, .icon-3 {
    position: absolute;
    left: 24.5%;
    top: 50%;
    width: 32px;
    height: 3px;
    background-color: #fff;
    transition: all 400ms cubic-bezier(.84,.06,.52,1.8);
    @include media-min($md) {
      left: calc(25% + 2px);
    }
}

.icon-1 {
  transform: translateY(-8px);
  animation-delay: 100ms;
}

.icon-3 {
  transform: translateY(8px);
  animation-delay: 250ms;
}

.hamburger-icon {
  position: relative;
  height: 70px;
  width: 70px; 
  z-index: 1000;
  cursor: pointer;
  border-radius:15px;
  transition: all 0.2s ease-in-out;
  background: rgba(7, 7, 7, 0.336);
  border: 1px solid rgba(255, 255, 255, 0.514);

  @include media-min($md){
    
    &:hover { 
      transform: scale(1.1);
      box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    }
  }
  @include media-max($md){
    height: 60px;
    width: 60px; 
    // transform: scale(.8);
  }

}
.hamburger-icon.active {
  @include media-max($md) {
  box-shadow: none;
}

}

.icon-1.a {
  transform: rotate(40deg);
}
.icon-3.b {
  transform: rotate(-40deg);
}
.icon-2.c {
  opacity: 0;
}

.clear {
  clear: both;
}

#nav {

  @include media-max($md){

    position: fixed;
    right: 0;
    width: 55%;
    top: 0;
    bottom: 0;
    z-index: 2;
    > ul {
      margin: 0;
      flex-direction: column;
      height: 100%;
      width: 100%;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      gap: 30px;
    }
  }

}
ul.dark_bg {
  @include media-max($md) {
    box-shadow: none;
  }
}