
main {
  background: #ffffff url("/assets/images/sample2.jpg") no-repeat right top;
  background-attachment: fixed;
  padding-bottom: 50px;
  
}

h1 {
  font-weight: 600;
  font-size: rem(80px);
  @include media-max($md){
    font-size: rem(50px);

  }

}
h2 {
  font-size: rem(60px);
  @include media-max($md){
    font-size: rem(40px);
  }
  @include media-max($sm){
    font-size: rem(26px);
  }

}
h3 {
  font-size: rem(45px);
  @include media-max($md){
    font-size: rem(30px);
  }
  @include media-max($sm){
    font-size: rem(20px);
  }
}
h4{
  font-size: rem(35px);
  @include media-max($md){
    font-size: rem(24px);
  }
  @include media-max($sm){
    font-size: rem(18px);
  }
}

.section_wrapper {
  min-height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  
}
.section_wrapper:nth-child(1){
  height: 100vh;
}
.section_wrapper:not(:nth-child(1)){
  margin-bottom: 50px;
}
.hero {
  display: flex;
  justify-content: space-between;
  color: $white;
  background: rgba(7, 7, 7, 0.336);
  backdrop-filter: blur(21px);
  gap: 50px;
  // text-shadow: 1px 0px 11px rgb(0, 0, 0);
  @include media-max ($lg) {
    align-items: center;
    gap: 25px;
  }

  @include media-max ($md)
   {
    flex-direction: column-reverse;
  }
 
  
  div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .main_picture {
    border-radius: 50%;
    max-width: 400px;
    max-height: 400px;
    object-fit: cover;
    @include media-max($lg) {

    }
    @include media-max($md) {
      max-width: 150px;
      max-height: 150px;
    }
  }
  .uk-divider-small {
    width: 100%;
  }
}
@media (max-width:960px) and (orientation: landscape) {
  .hero {
    flex-direction: initial;
  }
}
.glass_box  {
  position: relative;
  p,li {
    font-size: rem(32px);
    @include media-max($md) {
      font-size: rem(18px);

    }
  }
  li:not(.single_li) {
    margin: rem(15px 0);
    margin-left: 30px;
    @include media-max($md) {
      margin: rem(15px 25px);
      text-align: start;
    }
  }
  @include media-max($md) { 
    align-items: center;
    text-align: center;    
   
  }
  div.desc {
    width: 100%;
    @include media-max($md) { 
      align-items: center ;
    }


  }

}
.skills {
  .uk-slider-items {


    .uk-panel {
      position: relative;     
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.45);
        top: 0;
        left: 0;    
      }
     img {
      max-height: 400px;
      width: 100%;
      object-fit: cover;
     } 
    }
  }
}

