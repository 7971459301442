.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin:rem(0 0 50px);
  padding:rem(0 0 0 60px);

  li {

    .dots {
      padding:rem(5px 10px);
      color:#000;
    }
    
    a {
      padding:rem(5px 10px);
      color:#000;
      font-size:rem(18px);
      border-bottom:3px solid transparent;

      &:hover, &:focus {
        border-color:$link;
        font-weight:800;
        color:$link;
      }
    }

    &.pagination__item--prev, &.pagination__item--next {
      
      a {
        font-size:rem(40px);
        color:$link;

        &:hover, &:focus {
          border-color:transparent;
          font-weight:800;
          color:$link;
        }
      }
    }
    &.active {

      a {
        color:$link;
        font-weight:800;
        border-bottom:3px solid $link;
      }
    }
  }

}
