/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
  /* Hide the browser's default checkbox */
.container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
  /* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: rem(20px);
  width: rem(20px);
  background-color: transparent;
  border:1px solid #000;
  border-radius:5px;
}
input[type="radio"] ~ .checkmark {
  border-radius:1000px;
}
  /* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: rgba(255,255,255,0.2);
}
  /* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  box-shadow: none;
  outline:0 !important;
}
.container input:checked ~ .checkmark {
  background-color: transparent;
  border:1px solid #fff;
  outline:2px solid $link;
}
  /* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
  /* Style the checkmark/indicator */
.container .checkmark:after {
  display:none;
  content:'';
  position:absolute;
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  @include media-max(960px) {
    left:rem(7px);
    top:rem(3px);
  }
}