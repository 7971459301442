// ------------------------------------------------------------
// @include font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff);
// Use only woff2, woff formats, both are required
// Example: @include font-face('Font Name', $font-dir+'font-file-name', 400);
// @include font-face('Open Sans', $open-sans-dir+'OpenSans-Regular', 400);
// ------------------------------------------------------------
// Default fonts directory
$fonts-directory: '../assets/fonts/';
$font-dir:'font-dir/';

@font-face {
  font-family: 'Lato';
  src: url($fonts-directory + 'Lato-Regular.eot');
  src: url($fonts-directory + 'Lato-Regular.eot?#iefix') format('embedded-opentype'), url($fonts-directory + 'Lato-Regular.woff2') format('woff2'), url($fonts-directory + 'Lato-Regular.woff') format('woff'), url($fonts-directory + 'Lato-Regular.ttf') format('truetype'), url($fonts-directory + 'Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url($fonts-directory + 'Lato-Bold.eot');
  src: url($fonts-directory + 'Lato-Bold.eot?#iefix') format('embedded-opentype'), url($fonts-directory + 'Lato-Regular.woff2') format('woff2'), url($fonts-directory + 'Lato-Bold.woff') format('woff'), url($fonts-directory + 'Lato-Bold.ttf') format('truetype'), url($fonts-directory + 'Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: 500;
  font-style: normal;
}

