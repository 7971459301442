* {
  /*Webkit*/
  /* width */
  &::-webkit-scrollbar {
    transition: $t-bc;
    width: rem(10px);
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: lightgrey;
    border-radius: rem(10px);
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: $orange;
    border-radius: rem(10px);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: dimgrey;
  }
  /*Mozilla*/
  /* Handle Track */
  scrollbar-color: grey dimgrey;
  /* width */
  scrollbar-width: thin;
}
